import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['checkbox', 'hiddenCheckbox', 'hiddenContainer', 'selectionLabel'];
  static values = { inputName: String, selectionLabelOne: String, selectionLabelOther: String };

  connect() {
    this.selectedIdsValue = [];

    if (this.hasHiddenCheckboxTarget) {
      this.hiddenCheckboxTargets.forEach((checkbox) => {
        this.selectedIdsValue.push(checkbox.value);
      });
    }

    document.addEventListener("promote:sync-checkbox", this._syncCheckboxes.bind(this));
    document.addEventListener("promote:disable-hidden-fields", this._disableHiddenFields.bind(this));
    document.addEventListener("promote:enable-hidden-fields", this._enableHiddenFields.bind(this));
  }

  syncHiddenFields(event) {
    const checkbox = event.currentTarget;

    if (checkbox.checked) {
      this.selectedIdsValue.push(checkbox.value);
      this._createHiddenField(checkbox);
    } else {
      this.selectedIdsValue = this.selectedIdsValue.filter((id) => id !== checkbox.value);
      this._removeHiddenField(checkbox);
    }

    this._updateSelectionLabel();
    this._updateTooltip();
  }

  _syncCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => {
      if (this.selectedIdsValue.includes(checkbox.value)) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });

    document.dispatchEvent(new CustomEvent('promote:highlight-table-checkboxes'));
  }

  _updateSelectionLabel() {
    if (this.hasSelectionLabelTarget) {
      let newNumber = this.selectedIdsValue.length;
      let text;

      if (newNumber === 1) {
        text = this.selectionLabelOneValue;
      } else {
        text = this.selectionLabelOtherValue.replace(/\d+/, newNumber);
      }

      this.selectionLabelTarget.innerText = text;
    }
  }

  _updateTooltip() {
    if (this.hasSelectionLabelTarget) {
      let names = [];
      let text;

      this.hiddenCheckboxTargets.forEach((checkbox) => {
        names.push(checkbox.getAttribute("data-name"));
      });

      if (names.length > 0) {
        text = names.sort().join(", ");
      } else {
        text = "";
      }

      document.dispatchEvent(
        new CustomEvent("promote:tooltip-update-text", { detail: { text: text } })
      );
    }
  }


  _createHiddenField(checkbox) {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = this.inputNameValue;
    hiddenField.value = checkbox.value;
    hiddenField.dataset.checkboxPersistenceTarget = "hiddenCheckbox";
    hiddenField.setAttribute("data-id", checkbox.value);
    hiddenField.setAttribute("data-name", checkbox.dataset.name);

    this.hiddenContainerTarget.appendChild(hiddenField);
  }

  _removeHiddenField(checkbox) {
    const hiddenField = this.hiddenContainerTarget
      .querySelector(`input[data-id="${checkbox.value}"]`);

    if (hiddenField) hiddenField.remove();
  }

  _disableHiddenFields() {
    this.hiddenCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = true;
    });
  }

  _enableHiddenFields() {
    this.hiddenCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = false;
    });
  }
}
