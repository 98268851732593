import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = ['tableCellCheckbox', 'tableRow']

  connect() {
    this._boundHighlightExistingCheckboxes = this._highlightExistingCheckedRows.bind(this);

    document.addEventListener("promote:highlight-table-checkboxes", this._boundHighlightExistingCheckboxes);

    this._boundHighlightExistingCheckboxes();
  }

  disconnect() {
    document.removeEventListener("promote:highlight-table-checkboxes", this._boundHighlightExistingCheckboxes);
  }

  sort(event) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: event.target.dataset.sortableUrl,
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  highlightRow(event) {
    const target = event.target;

    this._highlightRow(target);
  }

  _highlightRow(target) {
    if (target.checked) {
      target.closest('.js-table-body-row').classList.add('selected');
    } else {
      target.closest('.js-table-body-row').classList.remove('selected');
    }
  }

  highlightAllRows(event) {
    if (event.target.checked) {
      this.tableRowTargets.forEach((row) => {
        row.classList.add('selected');
      });
    } else {
      this.tableRowTargets.forEach((row) => {
        row.classList.remove('selected');
      });
    }
  }

  _highlightExistingCheckedRows() {
    this.tableCellCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        this._highlightRow(checkbox)
      }
    });
  }
}
